

.main-wrapper {
     height: 100%;
     padding: 30px 30px 0;
     ::v-deep .el-scrollbar__wrap {
         overflow-x: hidden;
     }
     .big-title {
         font-size: 24px;
         margin: 30px 0 0 20px;
         line-height: 1;
         font-weight: bold;
     }
     .main-content {
         padding: 30px 60px 100px;
          .history-wrapper {
              
             & > .text {
                 font-size: 16px;
                 margin-right: 8px;
             }
             .search-list {
                     position: absolute;
                     left: 0;
                     top: 40px;
                     width: 350px;
                     height: 200px;
                     background: #fff;
                     border: 1px solid #DCDCDC;
                     box-sizing: border-box;
                     z-index: 9999;
                     .search-item {
                         line-height: 32px;
                         padding: 0 10px;
                         cursor: pointer;
                         transition: all .3s;
                         &:hover {
                             color: #2821fc;
                         }
                     }
                 }
         }
         .category-wrapper {
              margin-top: 35px;
             margin-bottom: 20px;
             padding: 20px 30px;
             background: #F6F6F6;
             border: 1px solid #eee;
             .category-level {
                 margin: 20px 0;
                 display: flex;
                 .level-item {
                     width: 398px;
                     height: 418px;
                     background: #fff;
                     margin-left: 16px;
                     border: 1px solid #E5E5E5;
                     &:first-child {
                         margin-left: 0;
                     }
                     .cate-item {
                         padding: 0 25px 0 15px;
                         line-height: 32px;
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         cursor: pointer;
                         .item-left {
                             width: 1%;
                             flex: 1;
                         }
                         .iconfont {
                             color: #9CA7B6;
                         }
                         &.current {
                             background: #E7F6EF;
                         }
                     }
                 }
             }
               .category-choose {
                 color: #666;
                 .text {
                     color: #999;
                 }
                 .value {
                     &:after {
                         content: ' > ';
                     }
                     &:last-child {
                         &:after {
                             content: none;
                         }
                     }
                 }
             }
         }
     }
 }
